<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
                label="Fotóalbum"
            >
              <validation-provider
                  #default="{ errors }"
                  name="fotóalbum"
                  rules="required"
                  vid="gallery"
              >
                <v-select
                    v-model="formData.gallery"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="galleryOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Árazás típus"
            >
              <validation-provider
                  #default="{ errors }"
                  name="árazás típus"
                  rules="required"
                  vid="pricingType"
              >
                <v-select
                    v-model="formData.pricingType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="pricingTypeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Rekordlista megfeleltetés"
            >
              <validation-provider
                  #default="{ errors }"
                  name="rekordlista megfeleltetés"
                  rules="required"
                  vid="location"
              >
                <v-select
                    v-model="formData.location"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="sortedLocationOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Név"
            >
              <validation-provider
                  #default="{ errors }"
                  name="név"
                  rules="required"
                  vid="name"
              >
                <b-form-input
                    v-model="formData.name"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Kód"
            >
              <validation-provider
                  #default="{ errors }"
                  name="kód"
                  rules="required|max:4"
                  vid="code"
              >
                <b-form-input
                    v-model="formData.code"
                    :state="errors.length > 0 ? false:null"
                    @input="refreshMapMarker"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Minimálisan fizetendő díj"
                description="A horgászhelyre történő foglalás esetén ennyit akkor is ki kell fizetni, ha paraméterek szerint számolt díj ennél kevesebb"
            >
              <validation-provider
                  #default="{ errors }"
                  name="minimálisan fizetendő díj"
                  rules="required"
                  vid="minimumFee"
              >
                <b-form-input
                    v-model="formData.minimumFee"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Min. felnőtt horgász száma"
                description="A hely foglalásához szükséges minimális felnőtt horgászok száma"
            >
              <validation-provider
                  #default="{ errors }"
                  name="min. felnőtt horgász száma"
                  rules="required"
                  vid="minAdult"
              >
                <b-form-input
                    v-model="formData.minAdult"
                    type="number"
                    min="1"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Min. felnőtt és ifi horgászok száma"
                description="A hely foglalásához szükséges minimális felnőtt és ifi horgászok együttes száma (felnőtt+ifi)"
            >
              <validation-provider
                  #default="{ errors }"
                  name="min. felnőtt és ifi horgászok száma"
                  rules="required"
                  vid="minAdultYoungster"
              >
                <b-form-input
                    v-model="formData.minAdultYoungster"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Max. felnőtt és ifi horgászok száma"
                description="A hely kapacitásának megfelelő, engedélyezhető maximális horgászlétszám (felnőtt+ifi)"
            >
              <validation-provider
                  #default="{ errors }"
                  name="max. felnőtt és ifi horgászok száma"
                  rules="required"
                  vid="maxAdultYoungster"
              >
                <b-form-input
                    v-model="formData.maxAdultYoungster"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Engedélyezhető egyéb létszám"
                description="Engedélyezhető egyéb létszám (gyermek + kísérő)"
            >
              <validation-provider
                  #default="{ errors }"
                  name="engedélyezhető egyéb létszám"
                  rules="required"
                  vid="maxChildAttendant"
              >
                <b-form-input
                    v-model="formData.maxChildAttendant"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Egyéb információ"
                description="Egyéb információ (infobuborék)"
            >
              <validation-provider
                  #default="{ errors }"
                  name="egyéb információ"
                  vid="moreInfo"
              >
                <editor
                    v-model="formData.moreInfo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Megjegyzés"
            >
              <validation-provider
                  #default="{ errors }"
                  name="megjegyzés"
                  vid="comment"
              >
                <editor
                    v-model="formData.comment"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="csomagszállításhoz szilárd burkolatú bejáratú út biztosított"
                vid="hasPavedRoad"
            >
              <b-form-checkbox
                  v-model="formData.hasPavedRoad"
                  :state="errors.length > 0 ? false:null"
              >
                Csomagszállításhoz szilárd burkolatú bejáratú út biztosított
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="külön fizetendő parkolási díj"
                vid="hasParkingFee"
            >
              <b-form-checkbox
                  v-model="formData.hasParkingFee"
                  :state="errors.length > 0 ? false:null"
              >
                Külön fizetendő parkolási díj
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="H/M zuhanyzási lehetőség, vízöblítéses WC biztosított"
                vid="hasShower"
            >
              <b-form-checkbox
                  v-model="formData.hasShower"
                  :state="errors.length > 0 ? false:null"
              >
                H/M zuhanyzási lehetőség, vízöblítéses WC biztosított
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="kiépített esőbeálló asztallal és padokkal (filagória) biztosított"
                vid="hasPavilion"
            >
              <b-form-checkbox
                  v-model="formData.hasPavilion"
                  :state="errors.length > 0 ? false:null"
              >
                Kiépített esőbeálló asztallal és padokkal (filagória) biztosított
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="kiépített tűzrakó hely biztosított"
                vid="hasFireplace"
            >
              <b-form-checkbox
                  v-model="formData.hasFireplace"
                  :state="errors.length > 0 ? false:null"
              >
                Kiépített tűzrakó hely biztosított
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="közvilágítás biztosított"
                vid="hasStreetlight"
            >
              <b-form-checkbox
                  v-model="formData.hasStreetlight"
                  :state="errors.length > 0 ? false:null"
              >
                Közvilágítás biztosított
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="Áramfelvételi lehetőség külön térítés mellett"
                vid="hasPowerConsumption"
            >
              <b-form-checkbox
                  v-model="formData.hasPowerConsumption"
                  :state="errors.length > 0 ? false:null"
              >
                Áramfelvételi lehetőség külön térítés mellett
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="hagyományos horgászati módszer választható"
                vid="canUseGeneralFishingMethod"
            >
              <b-form-checkbox
                  v-model="formData.canUseGeneralFishingMethod"
                  :state="errors.length > 0 ? false:null"
              >
                Hagyományos horgászati módszer választható
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="bojlis módszernél csónakhasználat térítésmentes lehetősége"
                vid="canUseBoat"
            >
              <b-form-checkbox
                  v-model="formData.canUseBoat"
                  :state="errors.length > 0 ? false:null"
              >
                Bojlis módszernél csónakhasználat térítésmentes lehetősége
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="lakókocsi, lakóbusz a hely közelében elhelyezhető"
                vid="canUseCaravan"
            >
              <b-form-checkbox
                  v-model="formData.canUseCaravan"
                  :state="errors.length > 0 ? false:null"
              >
                Lakókocsi, lakóbusz a hely közelében elhelyezhető
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="teljes sátor használható"
                vid="canUseTentFull"
            >
              <b-form-checkbox
                  v-model="formData.canUseTentFull"
                  :state="errors.length > 0 ? false:null"
              >
                Teljes sátor használható
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="csak félsátor használata engedélyezett"
                vid="canUseTentHalf"
            >
              <b-form-checkbox
                  v-model="formData.canUseTentHalf"
                  :state="errors.length > 0 ? false:null"
              >
                Csak félsátor használata engedélyezett
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
                #default="{ errors }"
                name="eseti, rejtett horgászhely"
                vid="isHidden"
            >
              <b-form-checkbox
                  v-model="formData.isHidden"
                  :state="errors.length > 0 ? false:null"
              >
                Eseti, rejtett horgászhely
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <!-- TODO: from database!!! -->
            <map-editor
                ref="map-editor"
                :map-url="baseUrl+'/img/terkep.svg'"
                :markers="markers"
            />
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import editor from '@/views/pages/_components/editor.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import mapEditor from '@/views/pages/_components/_map/MapEditor'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    editor,
    vSelect,
    cancelAndGOTOPreviousPage,
    mapEditor
  },
  data() {
    return {
      role: 'reservation',
      formData: {
        gallery: '',
        pricingType: '',
        location: '',
        name: '',
        code: '',
        minimumFee: '',
        minAdult: 1,
        minAdultYoungster: 2,
        maxAdultYoungster: '',
        maxChildAttendant: '',
        moreInfo: '',
        comment: '',
        mapX: 0,
        mapY: 0,
        /* Checkboxes */
        hasPavedRoad: false,
        hasParkingFee: false,
        hasShower: false,
        hasPavilion: false,
        hasFireplace: false,
        hasStreetlight: false,
        hasPowerConsumption: false,
        canUseGeneralFishingMethod: false,
        canUseBoat: false,
        canUseCaravan: false,
        canUseTentFull: false,
        canUseTentHalf: false,
        isHidden: false,
      },
      markers: [],
      baseUrl: process.env.VUE_APP_SERVER_BASE_URL,
      galleryOptions: [],
      pricingTypeOptions: [],
      locationOptions: [],
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchGalleriesForSelect').then(response => {
      this.galleryOptions = response
      this.formData.gallery = this.galleryOptions[0]
    })
    this.$store.dispatch('fetchPricingTypesForSelect').then(response => {
      this.pricingTypeOptions = response
      this.formData.pricingType = this.pricingTypeOptions[0]
    })
    this.$store.dispatch('fetchLocationsForSelect').then(response => {
      this.locationOptions = response
      this.formData.location = this.locationOptions[0]
    })

    this.markers.push({
      id: 1,
      editable: true,
      position: {
        x: this.formData.mapX,
        y: this.formData.mapY,
      },
      label: this.formData.code,
      slug: this.$helpers.slug(this.formData.code),
    })
  },
  computed: {
    sortedLocationOptions() {
      return this.locationOptions.slice().sort(function(a, b){
        return a.title.localeCompare(b.title)
      });
    }
  },
  methods: {
    submitForm() {

      let mapEditorData = this.$refs["map-editor"].getData()
      let marker = mapEditorData.markers[0]

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.gallery = this.formData.gallery.value
          fd.pricingType = this.formData.pricingType.value
          fd.location = this.formData.location.value
          fd.mapX = marker.position.x
          fd.mapY = marker.position.y


          this.$store.dispatch('storeReservationFishingSpot', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservation-fishing-spots'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    refreshMapMarker() {
      this.markers[0].label = this.formData.code
      this.markers[0].slug = this.$helpers.slug(this.formData.code)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
